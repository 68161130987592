import React from "react";

export function Who() {
  return (
    <section id="who" className="py-14 bg-neutral-light-grayish-blue lg:py-24">
      <div className="container text-center lg:text-left">
        <div className="grid mb-12 lg:mb-16">
          <div className="col-span-1">
            <h2 className="pb-5 text-3xl lg:text-4xl text-primary-dark-green">
              Wie?
            </h2>
            <h3 className="text-gray-800 font-bold mb-7">Rentattest partnernetwerk</h3>

            <p className="text-sm leading-5 text-neutral-grayish-blue lg:text-base mb-2">
              Rentattest is exclusief beschikbaar via BIV-erkende vastgoedmakelaars die officieel partner zijn van ons
              netwerk.
            </p>

            <p className="text-sm leading-5 text-neutral-grayish-blue lg:text-base mb-2">
              Het attest fungeert als een kwaliteitslabel dat garant staat voor objectiviteit, neutraliteit en respect
              voor privacy. Alle aangesloten makelaars hebben daartoe een Charter van Goed Gebruik ondertekend.
            </p>

            <p className="text-sm leading-5 text-neutral-grayish-blue lg:text-base mb-2">
              Dit waarborgt dat elke partner zich houdt aan de hoogste standaarden in de sector.
            </p>

            <h3 className="text-gray-800 font-bold mb-7 mt-7">Gerechtsdeurwaarder</h3>

            <p className="text-sm leading-5 text-neutral-grayish-blue lg:text-base mb-2">
              Volgens artikel 519, §2, 14° van het Gerechtelijk Wetboek is de gerechtsdeurwaarder bevoegd voor het
              uitvoeren van solvabiliteitsonderzoeken en het opstellen van bijbehorende rapporten. Rentattest werkt
              samen met gerechtsdeurwaarders om gedetailleerde analyses en attesten te leveren.
            </p>

            <p className="text-sm leading-5 text-neutral-grayish-blue lg:text-base mb-2">
              Wanneer je een aanvraag indient, analyseert de gerechtsdeurwaarder jouw solvabiliteit op basis van
              niet-openbare en onveranderlijke gegevens. Dit omvat onder andere je adresgeschiedenis,
              woonomgevingsprofiel, uithuiszettingen, beslagberichten, en lopende uitvoeringen, aangevuld met eigen
              gegevens.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

import {Link} from "react-router-dom";
import {PageNames} from "../pages/pages";
import React from "react";

export function VraagSolvabiliteitsAttestAanButton() {
  let style = "py-3 px-7 text-xs rentattest-gradient rounded-md ring-primary-sky-blue lg:block " +
    "bg-primary-lime-green text-neutral-white hover:button-brightness focus:outline-none focus:ring font-medium whitespace-nowrap";
  return <Link
    className={style}
    to={PageNames.ITSME}>
    Vraag attest aan
  </Link>;
}

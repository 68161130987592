import React from "react";

export function Gdw() {
  return (
    <a
      className="text-primary-cadet-blue"
      href="https://www.gdw-gent.be/"
      target="_blank"
      rel="noreferrer">
      GDW-Gent
    </a>
  )
}

import {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import ReactGA from "react-ga4";

const useGaTracker = () => {
  const location = useLocation();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    function isNotLocalhost() {
      return !window.location.href.includes("localhost");
    }

    function isNotDev() {
      return !window.location.href.includes('https://dev.solvattest.be');
    }

    if (isNotLocalhost() && isNotDev()) {
      console.log("initialize")
      ReactGA.initialize("G-WNRQBXX19X");
      setInitialized(true);
    }
  }, []);

  useEffect(() => {
    if (initialized) {
      console.log("pageview")
      ReactGA.send({hitType: "pageview", page: location.pathname + location.search});
    }
  }, [initialized, location]);
};

export default useGaTracker;

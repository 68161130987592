import {VraagSolvabiliteitsAttestAanButton} from "./VraagSolvabiliteitsAttestAanButton";
import {NavItem} from "./NavItem.d";
import {PageNames} from "../pages/pages";
import React from "react";

export default function Footer() {
  const navItems: NavItem[] = [
    {label: 'Privacy Policy', link: PageNames.PRIVACY_POLICY},
    {label: 'Cookie Policy', link: PageNames.COOKIE_POLICY},
    {label: 'Algemene voorwaarden', link: PageNames.ALGEMENE_VOORWAARDEN},
  ];

  return (
    <footer id="footer" className="bg-primary-dark pt-10 pb-80px text-white text-sm">
      <div className="container">
        <div className="text-center grid grid-cols-1 justify-items-center gap-6 lg:grid-cols-6 lg:gap-0">
          <div className="flex flex-col justify-between lg:justify-self-start lg:col-span-3">
            <img
              className="mb-7 w-28"
              src="rentattest-white.png"
              alt="rentattest logo"
            />
          </div>

          <div className="flex flex-col justify-between items-center lg:items-end lg:justify-self-end lg:col-span-3">
            <div className="mt-2">
              <a className="text-primary-sky-blue hover:text-primary-cadet-blue block lg:text-right text-center mb-5"
                 href="mailto:info@rentattest.be">info@rentattest.be</a>
              {navItems.map((item) =>
                <a className="hover:text-primary-cadet-blue block lg:text-right text-center"
                   href={item.link}
                   key={item.link}
                >
                  {item.label}
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

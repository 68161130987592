export enum PageNames {
  HOME = "/",
  MOLLIE = "/mollie",
  ORDER = "/order",
  PAYMENT_RECEIVED = "/payment-received",
  ITSME = "/itsme",
  NRN = "/nrn",
  PRIVACY_POLICY = "/privacy-policy",
  COOKIE_POLICY = "/cookie-policy",
  ALGEMENE_VOORWAARDEN = "/algemenevoorwaarden",
}

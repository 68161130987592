import React from "react";
import {VraagSolvabiliteitsAttestAanButton} from "./VraagSolvabiliteitsAttestAanButton";


export function Hero() {
  return (
    <section id="hero" className="relative">
      <Background/>
      <div className="flex items-center container relative z-20 min-h-screen lg:h-screen lg:block">
        <div className="flex flex-col justify-center h-full lg:flex-row lg:justify-between gap-8">
          <div
            className="flex flex-col order-2 justify-center pb-4 h-full lg:pb-0 lg:w-96 lg:justify-center lg:order-none">
            <Body></Body>
          </div>
          <div className="flex flex-1 order-1 items-center lg:order-none lg:h-auto">
              <img className="w-11/12" src="/rentattest-hero-image.png" alt="image rentattest.be eerlijke screening, zorgeloos verhuren"/>
          </div>
        </div>
      </div>
    </section>
  );
}


function Body() {
  return (
    <div
      className="flex flex-col justify-center items-center h-1/2 text-center lg:items-start lg:text-left">
      <h1 className="h1">
        Bewijs je solvabiliteit
      </h1>
      <p className="mb-7 text-xs leading-5 text-primary-dark lg:text-neutral-grayish-blue lg:text-base">
        Maak huren en verhuren eenvoudig en zorgeloos.
      </p>
      <p className="mb-7 text-xs leading-5 text-primary-dark lg:text-neutral-grayish-blue lg:text-base">
        Versterk je kansen als huurder met een objectief solvabiliteitsrapport.
      </p>
      <p className="mb-7 text-xs leading-5 text-primary-dark lg:text-neutral-grayish-blue lg:text-base">
        Vind als verhuurder snel de ideale kandidaat met een solvabiliteitsrapport dat onmiddellijk inzicht geeft.
      </p>
      <p className="mb-7 text-xs leading-5 text-primary-dark lg:text-neutral-grayish-blue lg:text-base">
        Vraag hier je Rentattest aan met de inlogcode ontvangen van je vastgoedmakelaar.
      </p>
      <VraagSolvabiliteitsAttestAanButton/>
      <p className="mt-7 mb-7 text-xs leading-5 text-primary-dark lg:text-neutral-grayish-blue lg:text-base">
        Geen inlogcode?
      </p>
      <p className="mb-7 text-xs leading-5 text-primary-dark lg:text-neutral-grayish-blue lg:text-base">
        Contacteer [hyperlink] ons we verwijzen je graag door naar een partner-vastgoedmakelaar van Rentattest.
      </p>
    </div>
  )
}

function Background() {
  return (
    <div
      className="absolute w-full min-h-full lg:h-full bg-left bg-no-repeat bg-header-desktop bg-custom-header-size lg:block"/>
  )
}


import {Route, Switch} from "react-router-dom";
import {PageNames} from "./pages/pages";
import {Home} from "./pages/Home";
import {Order} from "./pages/Order";
import {Mollie} from "./pages/Mollie";
import {PaymentReceived} from "./pages/PaymentReceived";
import {Itsme} from "./pages/Itsme";
import {PrivacyPolicy} from "./pages/PrivacyPolicy";
import {CookiePolicy} from "./pages/CookiePolicy";
import React from "react";
import useGaTracker from "./useGaTracker";
import {AlgemeneVoorwaarden} from "./pages/AlgemeneVoorwaarden";

export default function Root() {
  useGaTracker();
  return (
    <div>
      <Switch>
        <Route exact path={PageNames.HOME}>
          <Home/>
        </Route>
        <Route path={PageNames.ORDER}>
          <Order/>
        </Route>
        <Route path={PageNames.MOLLIE}>
          <Mollie/>
        </Route>
        <Route path={PageNames.PAYMENT_RECEIVED}>
          <PaymentReceived/>
        </Route>
        <Route path={PageNames.ITSME}>
          <Itsme/>
        </Route>
        <Route path={PageNames.NRN}>
          <Order/>
        </Route>
        <Route path={PageNames.PRIVACY_POLICY}>
          <PrivacyPolicy/>
        </Route>
        <Route path={PageNames.COOKIE_POLICY}>
          <CookiePolicy/>
        </Route>
        <Route path={PageNames.ALGEMENE_VOORWAARDEN}>
          <AlgemeneVoorwaarden/>
        </Route>
      </Switch>
    </div>
  )
}

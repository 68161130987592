import React from "react";
import {Gdw} from "./Gdw";
import JsxParser from "react-jsx-parser";

export function What() {
  return (
    <section id="what" className="py-14 bg-neutral-light-grayish-blue lg:py-24">
      <div className="container text-center lg:text-left">
        <div className="grid mb-12 lg:grid-cols-2 lg:mb-16">
          <div className="col-span-1">
            <h2 className="pb-5 text-3xl lg:text-4xl text-primary-dark-green">
              Wat doet rentattest?
            </h2>
            <p className="text-sm leading-loose text-neutral-grayish-blue lg:text-base">
              Rentattest helpt verhuurders bij het selecteren van betrouwbare huurders door middel van een geavanceerde en objectieve risicoanalyse.
            </p>
            <p className="mt-4 text-sm leading-loose text-neutral-grayish-blue lg:text-base">
              In samenwerking met erkende gerechtsdeurwaarders beoordelen we de solvabiliteit en betrouwbaarheid van potentiële en bestaande huurders. Ons proces biedt volledige privacy en gegevensbescherming, terwijl het zorgt voor transparantie en nauwkeurige besluitvorming.
            </p>
            <p className="mt-4 text-sm leading-loose text-neutral-grayish-blue lg:text-base">
              Zo kunnen verhuurders met vertrouwen de beste huurders kiezen, terwijl huurders een eerlijke kans krijgen om op basis van objectieve criteria in aanmerking te komen voor een huurwoning.
            </p>
          </div>
        </div>
        <div className="grid grid-cols-1 gap-9 lg:gap-6 lg:grid-cols-4">
          {motivationItems.map(({iconPath, subtitle, title}) => (
            <div key={title} className="justify-center">
              <div className="flex justify-center lg:justify-start">
                <img src={iconPath} alt=""/>
              </div>

              <h2 className="py-4 text-lg text-primary-dark-green lg:pt-9 lg:pb-6 lg:text-xl lg:font-bold">
                {title}
              </h2>
              <div className="text-sm font-light leading-5 break-words text-neutral-grayish-blue lg:text-base">
                <JsxParser components={{Gdw}} jsx={subtitle}/>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

const motivationItems: { subtitle: string; iconPath: string; title: string }[] = [
  {
    iconPath: '/icons/icon-online.svg',
    title: 'Kwaliteitslabel',
    subtitle:
      'Rentattest fungeert als een kwaliteitslabel dat zekerheid biedt over financiële betrouwbaarheid en transparantie in de huursector.',
  },
  {
    iconPath: '/icons/icon-budgeting.svg',
    title: 'Comfort',
    subtitle:
      'Verhuurders krijgen een inzicht in de betrouwbaarheid en solvabiliteit van de huurder, de huurder vergroot zijn of haar kansen op een succesvolle huuraanvraag .',
  },
  {
    iconPath: '/icons/icon-onboarding.svg',
    title: 'Objectief',
    subtitle:
      'Rentattest garandeert een objectieve beoordeling, gebaseerd op onbeïnvloedbare en betrouwbare gegevens, voor een eerlijke huurdersselectie.',
  },
  {
    iconPath: '/icons/icon-api.svg',
    title: 'GDPR-Conform',
    subtitle:
      'Door de integratie met Itsme is het enkel mogelijk voor de huurder zelf om een Rentattest aan te vragen.',
  },
];

import {Navbar} from "../components/Navbar";
import React from "react";
import Footer from "../components/Footer";

export function AlgemeneVoorwaarden() {
  return (
    <div className="flex flex-col justify-center min-h-screen mt-32">
      <Navbar/>
      <div className="container relative lg:block pb-10 policy">
        <h1>Algemene voorwaarden</h1>
        <h2>Factuurvoorwaarden</h2>
        <p>
          Alle facturen zijn contant en zonder korting betaalbaar te Gent. Bij gehele of gedeeltelijke niet-betaling op
          de vervaldag zal het factuurbedrag van rechtswege en zonder ingebrekestelling verhoogd worden met een rente
          van 10% per jaar vanaf de vervaldag.
        </p>
        <p>
          Daarnaast zal het onbetaalde bedrag eveneens verhoogd worden met een
          forfaitaire schadevergoeding van 10 % met een minimum van 50,00 euro en een maximum van 1.860,00 euro, zelf
          bij toekenning van een termijn van afbetaling en zonder inbreuk te doen aan de mogelijkheden voorzien in
          artikel 1244 B.W..
        </p>
        <p>In elk geval en onverminderd het bovenstaande geeft het niet-nakomen van de
          betalingsverplichting ons het recht de dienstverlening op te schorten. In geval van betwisting zijn enkel de
          rechtbanken van het rechtsgebied Gent bevoegd. De betwisting van een factuur dient binnen de 8 dagen na
          ontvangst van de factuur bij aangetekend schrijven, geadresseerd aan de vennootschapszetel, kenbaar gemaakt te
          worden.
          De verzoeker die de BTW recupereert op kosten die reeds door de verweerder/schuldenaar werden betaald, dient
          dit bedrag aan BTW terug te storten naar deze laatste.
        </p>
      </div>
      <Footer/>
    </div>
  );
}

import React from "react";
import {Pricing} from "../components/Pricing";
import Footer from "../components/Footer";
import {Hero} from "../components/Hero";
import {What} from "../components/What";
import {Navbar} from "../components/Navbar";
import {How} from "../components/How";
import {Who} from "../components/Who";

export function Home() {
  return (
    <div className="flex flex-col justify-center min-h-screen text-white">
      <Navbar/>
      <div className="overflow-hidden relative">
        <Hero/>
        <What/>
        <How/>
        <Who/>
      </div>
      <div className="container flex flex-col items-center text-xl">
        <section
          className="flex flex-col items-center py-6 lg:pt-20 w-full lg:justify-center text-gray-800">
          <p>
            Op zoek naar een Rentattest-vastgoedmakelaar in jouw regio?
          </p>
          <p>
            Of ben je zelf een vastgoedmakelaar en wil je Rentattest-partner worden?
          </p>
          <p className="mt-10 mb-10">
            Neem contact [hyperlink] met ons op.
          </p>
        </section>
      </div>
      <Footer/>
    </div>
  )
}

import React from "react";
import {ItsmeButton} from "../components/ItsmeButton";
import {AppConfig} from "../appConfig";
import {Gdw} from "../components/Gdw";

export function Itsme() {
  const config = AppConfig.getInstance();

  const handleClick = () => {
    let scope = `openid service:${config.itsmeLoginScope} eid`
    let client_id = config.itsmeClientId
    let redirect_base_url = config.appBaseUrl
    let redirect_path = "/nrn"
    let redirect_uri = redirect_base_url + redirect_path
    let url = `https://idp.${config.itsmeEnvironment}.itsme.services/v2/authorization?client_id=${client_id}&response_type=code&scope=${scope}&redirect_uri=${redirect_uri}`
    window.open(encodeURI(url), "_self")
  }

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 max-w-md mx-auto sm:max-w-xl">
          <div>
            <p className="text-center my-4">
              Klik om via itsme uw rijksregisternummer op te halen.
            </p>
            <ItsmeButton text="Deel gegevens met itsme" onPress={handleClick}/>
          </div>
        </div>
        <p className="text-center text-primary-sky-blue text-xs">
          &copy;2021 <Gdw/>. Alle rechten voorbehouden.
        </p>
      </div>
    </div>
  )
}
